import { rollup, sum } from 'd3-array'
import { getByYear } from '../../util'

const CONSUMPTION = 'kWh'
const EMISSION = 'CO2e kg/kWh'
const RENEWABLE_RATIO = 'renewable ratio'

export const X_PROP = 'date'

function getHeatingEnergyPerEra(eraBySiteYear, energy, toggleY) {
  const filteredEnergy = energy.filter((d) => d.category === 'heating')
  const data = filteredEnergy.map((d) => ({
    date: d.year,
    location: d.site,
    consumption: d[CONSUMPTION],
  }))

  if (toggleY) {
    const weightedAvgSeries = getWeightedAvgSeries(eraBySiteYear, data)

    return [data, weightedAvgSeries]
  }

  return data
}

function getElectricityEnergyPerEra(eraBySiteYear, energy, toggleY) {
  const filteredEnergy = energy.filter((d) => d.category === 'electricity')
  const data = filteredEnergy.map((d) => ({
    date: d.year,
    location: d.site,
    consumption: d[CONSUMPTION],
  }))

  if (toggleY) {
    const weightedAvgSeries = getWeightedAvgSeries(eraBySiteYear, data)

    return [data, weightedAvgSeries]
  }

  return data
}

function getWeightedAvgSeries(eraBySiteYear, data) {
  const transformedData = data.map((d) => ({
    date: d.date,
    consumption: d.consumption,
    era: eraBySiteYear(d.location, d.date),
  }))

  const summedData = Object.values(
    transformedData.reduce((acc, curr) => {
      const { date, consumption, era } = curr

      const sum = acc?.[date]?.sum ? acc[date].sum + consumption : consumption
      const eraInYear = acc?.[date]?.eraInYear ? acc[date].eraInYear + era : era

      return {
        ...acc,
        [date]: {
          ...acc[date],
          date,
          sum,
          eraInYear,
        },
      }
    }, {})
  )

  const weightedAvgSeries = summedData.map((d) => ({
    date: d.date,
    weightedAvg: d.sum / d.eraInYear,
  }))

  return weightedAvgSeries
}

function getFossilEnergyConsumptionData(energy) {
  const data = energy.map((d) => {
    const emission = d[EMISSION] * d[CONSUMPTION]
    return {
      date: d.year,
      category: d.category,
      type: d.type,
      emission,
    }
  })

  return data
}

function getRenewableEnergyRatioData(energy) {
  const data = energy.map((d) => ({
    date: d.year,
    category: d.category,
    renewable_ratio: d[CONSUMPTION] * d[RENEWABLE_RATIO],
    consumption: d[CONSUMPTION],
  }))

  return data
}

function getEnergyEfficiencyAchievedData(energyEfficiencyAchieved) {
  const data = energyEfficiencyAchieved.map((d) => ({
    date: d.year,
    achieved: d.achieved,
  }))

  return data
}

function transformWithEra(eraBySiteYear, d) {
  const keysToFilter = [X_PROP]
  const date = d[X_PROP]
  const locationsInYear = Object.entries(d)
    .filter(([key]) => !keysToFilter.includes(key))
    .map(([key, value]) => [key, value / eraBySiteYear(key, date)])

  return {
    ...d,
    ...Object.fromEntries(locationsInYear),
  }
}

// Cockpit page functions
function getHeatingData(eraBySiteYear, data) {
  const energy = data
    .filter((d) => d.category === 'heating')
    .map((d) => ({
      x: d.year,
      y: d[CONSUMPTION] / eraBySiteYear(d.site, d.year),
    }))

  return getByYear(energy)
}

function getElectricityData(eraBySiteYear, data) {
  const energy = data
    .filter((d) => d.category === 'electricity')
    .map((d) => ({
      x: d.year,
      y: d[CONSUMPTION] / eraBySiteYear(d.site, d.year),
    }))

  return getByYear(energy)
}

function getFossilEnergyData(eraBySiteYear, data) {
  const energy = data.map((d) => ({
    x: d.year,
    y: (d[EMISSION] * d[CONSUMPTION]) / eraBySiteYear(d.site, d.year),
  }))

  return getByYear(energy)
}

function getRenewableData(data, category) {
  const energy = data.filter((d) => d.category === category)

  const totalHeatConsumptionByYear = rollup(
    energy,
    (v) => sum(v, (d) => d[CONSUMPTION]),
    (d) => d.year
  )

  const renewable = energy.map((d) => ({
    x: d.year,
    y: d[CONSUMPTION] * d[RENEWABLE_RATIO],
  }))

  const totalRenewable = getByYear(renewable).map((d) => ({
    x: d.x,
    y: (d.y / totalHeatConsumptionByYear.get(d.x)) * 100,
  }))

  return totalRenewable
}

function getEnergyEfficiencyData(data) {
  return data
    .map((d) => ({
      x: d.year,
      y: (d.achieved ? 1 : 0) * 100,
    }))
    .slice()
    .sort((a, b) => a.x.localeCompare(b.x))
}

export {
  getHeatingEnergyPerEra,
  getElectricityEnergyPerEra,
  getFossilEnergyConsumptionData,
  getRenewableEnergyRatioData,
  getEnergyEfficiencyAchievedData,
  transformWithEra,
  getHeatingData,
  getElectricityData,
  getFossilEnergyData,
  getRenewableData,
  getEnergyEfficiencyData,
}
