import React, { useCallback, useMemo } from 'react'
import { extent, max } from 'd3-array'
import { curveLinear } from '@visx/curve'
import { Group } from '@visx/group'
import { scaleLinear } from '@visx/scale'
import { LinePath } from '@visx/shape'
import { format } from 'd3-format'
import 'twin.macro'

import useSVGTooltip from '../connectors/useSvgTooltip'
import OverviewTooltipContent from './OverviewTooltipContent'

const lineHeight = 18

const getX = (d) => d.x
const getY = (d) => d.y

const width = 77
const svgHeight = 46

const valueFormatter = format('.1f')

let tooltipTimeout

export default function CockpitChart({ data = [], unit, hideValue = false }) {
  const xScale = useMemo(
    () => scaleLinear({ domain: extent(data, getX), range: [0, width - 25] }),
    [data]
  )
  const yScale = useMemo(
    () =>
      scaleLinear({
        domain: [0, max(data, getY)],
        range: [lineHeight - 2, 0],
      }),
    [data]
  )

  const {
    containerRef,
    handlePointer,
    TooltipInPortal,
    hideTooltip,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    tooltipData,
    tooltipStyles,
  } = useSVGTooltip()

  const handleTooltip = useCallback(
    (e, { x, y }) => {
      if (tooltipTimeout) clearTimeout(tooltipTimeout)

      handlePointer(e, {
        label: x,
        value: hideValue ? '' : valueFormatter(y),
        unit: typeof unit === 'function' ? unit(y) : unit,
      })
    },
    [handlePointer, unit, hideValue]
  )

  const handlePointerLeave = useCallback(() => {
    tooltipTimeout = window.setTimeout(() => {
      hideTooltip()
    }, 300)
  }, [hideTooltip])

  return (
    <svg tw="inline-block" width={width} height={svgHeight} ref={containerRef}>
      <Group top={15} left={20}>
        {data.map((d, j) => (
          <circle
            key={j}
            r={4}
            stroke="white"
            strokeWidth="2"
            tw="text-grey-7 fill-current cursor-default"
            cx={xScale(getX(d))}
            cy={yScale(getY(d))}
            onPointerEnter={(e) => handleTooltip(e, { x: getX(d), y: getY(d) })}
            onPointerLeave={handlePointerLeave}
          />
        ))}
        <LinePath
          curve={curveLinear}
          data={data}
          x={(d) => xScale(getX(d)) ?? 0}
          y={(d) => yScale(getY(d)) ?? 0}
          shapeRendering="geometricPrecision"
          tw="stroke-current text-grey-7 text-opacity-40 cursor-default"
        />
      </Group>

      {tooltipOpen && (
        <>
          <TooltipInPortal
            left={tooltipLeft}
            top={tooltipTop}
            style={tooltipStyles}
          >
            <OverviewTooltipContent tooltipData={tooltipData} />
          </TooltipInPortal>
        </>
      )}
    </svg>
  )
}
