import React from 'react'
import CockpitBadge from './CockpitBadge'
import 'twin.macro'

export default function CockpitExperimentsItem({ title, subtitle, badges }) {
  return (
    <div tw="px-4 pt-3 pb-5 shadow-inner">
      <div tw="flex items-center">
        <div tw="flex flex-col ">
          <span tw="text-grey-1">{title}</span>
          <span tw="text-sm text-grey-7">{subtitle}</span>
        </div>
      </div>
      {badges && (
        <div tw="-ml-1 mt-2 flex flex-wrap">
          {badges.map((badge) => (
            <CockpitBadge key={badge} value={badge} tw="ml-1 mt-1" />
          ))}
        </div>
      )}
    </div>
  )
}
