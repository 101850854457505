import { getByYear } from '../../util'

const EMISSION = 'CO2e g/km'
const KILOMETERS = 'km'

function getCarsData(yearlyFte, data) {
  const emission = data.map((car) => {
    const year = car.date
    const emission = (car[EMISSION] * car[KILOMETERS]) / 1000 / yearlyFte[year]

    return {
      x: year,
      y: emission,
    }
  })

  return getByYear(emission)
}

export { getCarsData }
