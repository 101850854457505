import React from 'react'
import { Link } from 'gatsby'
import { format } from 'd3-format'
import tw from 'twin.macro'

import CockpitPercentageBadge from './CockpitPercentageBadge'
import CockpitChart from './CockpitChart'
import InfoTooltip from './InfoTooltip'

const getLastItem = (list) => list[list.length - 1]

function Container({ link, children }) {
  return link ? (
    <Link
      to={link}
      tw="block focus:outline-none focus-visible:(outline-none ring-2 ring-grey-3 ring-inset border-transparent) rounded"
    >
      {children}
    </Link>
  ) : (
    <>{children}</>
  )
}

export default function CockpitCard({
  title,
  lineData,
  link,
  isInactive, // TODO: Rename this to inactive
  showTooltip,
  unit,
  hideValue,
  customColor,
}) {
  const sortedLineData = lineData
    ?.slice()
    .sort((a, b) => a.x.localeCompare(b.x))

  return (
    <div>
      <Container link={!isInactive && link}>
        <div
          css={[
            tw` h-24 p-4 flex justify-between items-center shadow-inner text-grey-1`,
            isInactive &&
              tw`text-grey-3 text-opacity-50 bg-grey-6 cursor-not-allowed`,
          ]}
        >
          <div>
            <div tw="relative text-sm">
              <span>{title}</span>
              {showTooltip && (
                <InfoTooltip
                  containerStyles={tw`inline-block align-middle ml-0.5`}
                  contentStyles={tw`w-max text-xs p-2 -left-8`}
                  placement="top"
                >
                  Based on partial data availability.
                </InfoTooltip>
              )}
            </div>
            {sortedLineData && (
              <div
                css={[
                  tw`text-xs text-grey-7`,
                  isInactive && tw`text-transparent`,
                ]}
              >
                {!hideValue && format('.1f')(getLastItem(sortedLineData).y)}{' '}
                {typeof unit === 'function'
                  ? unit(getLastItem(sortedLineData).y)
                  : unit}
              </div>
            )}
          </div>
          {hasValidValues(sortedLineData) && (
            <div tw="flex items-center">
              <CockpitChart
                data={sortedLineData}
                unit={unit}
                hideValue={hideValue}
              />
              <CockpitPercentageBadge
                data={sortedLineData}
                customColor={customColor}
                tw="ml-3"
              />
            </div>
          )}
        </div>
      </Container>
    </div>
  )
}

function hasValidValues(data) {
  if (data) {
    // TODO: Check data calc to not return Inf values
    const hasInfiniteValues = data.some((d) => !isFinite(d.y))
    return hasInfiniteValues ? false : true
  }
  return false
}
