import { getByYear } from '../../util'

function getWaterData(data) {
  const water = data.map((d) => ({
    x: d.year,
    y: d.m3,
  }))

  return getByYear(water)
}

export { getWaterData }
