import { groups } from 'd3-array'

const WASTE = 'g waste/meal'
const CONSUMPTION = 'g meat/meal'
const COUNT = 'meal count'

function getMeatData(data) {
  const dataByYear = groups(data, (d) => d.year)
  const result = dataByYear.reduce((all, [year, yearItems]) => {
    const yearlyMeatConsumption = yearItems.reduce(
      (sum, d) => sum + d[CONSUMPTION] * d[COUNT],
      0
    )

    const yearlyMealCount = yearItems.reduce((sum, d) => sum + d[COUNT], 0)
    const avgYearlyConsumption = yearlyMeatConsumption / yearlyMealCount
    return [
      ...all,
      {
        x: year,
        y: avgYearlyConsumption,
      },
    ]
  }, [])

  return result
}

function getFoodWasteData(data) {
  const dataByYear = groups(data, (d) => d.year)
  const result = dataByYear.reduce((all, [year, yearItems]) => {
    const yearlyWaste = yearItems.reduce(
      (sum, d) => sum + d[WASTE] * d[COUNT],
      0
    )

    if (isNaN(yearlyWaste)) {
      return all
    }

    const yearlyMealCount = yearItems.reduce((sum, d) => sum + d[COUNT], 0)
    const avgYearlyWaste = yearlyWaste / yearlyMealCount

    return [
      ...all,
      {
        x: year,
        y: avgYearlyWaste,
      },
    ]
  }, [])

  return result
}

export { getMeatData, getFoodWasteData }
