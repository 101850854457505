import React from 'react'
import tw from 'twin.macro'

import Layout from '../components/Layout'
import CockpitList from '../components/CockpitList'
import CockpitHeader from '../components/CockpitHeader'
import CockpitExperimentsItem from '../components/CockpitExperimentsItem'
import { getAirData } from '../components/Air/utils'
import { getCarsData } from '../components/Cars/utils'

// SVG components
import Mobility from '../images/svg/mobility.svg'
import House from '../images/svg/house.svg'
import Globe from '../images/svg/globe.svg'
import Experiments from '../images/svg/experiments.svg'
import CockpitCard from '../components/CockpitCard'
import {
  getElectricityData,
  getEnergyEfficiencyData,
  getFossilEnergyData,
  getHeatingData,
  getRenewableData,
} from '../components/Energy/utils'
import { getFoodWasteData, getMeatData } from '../components/Food/utils'
import { getPaperData } from '../components/Paper/utils'
import { getWaterData } from '../components/Water/utils'
import useData, { useHelperData } from '../data/useData'

export default function Cockpit() {
  const {
    cars,
    energy,
    energyEfficiencyAchieved,
    flightLegs,
    food,
    paper,
    water,
  } = useData()
  const { eraBySiteYear, yearlyFte } = useHelperData()

  const airData = flightLegs && getAirData(yearlyFte, flightLegs)
  const carsData = cars && getCarsData(yearlyFte, cars)

  const heatingData = energy && getHeatingData(eraBySiteYear, energy)
  const electricityData = energy && getElectricityData(eraBySiteYear, energy)
  const fossilData = energy && getFossilEnergyData(eraBySiteYear, energy)
  const renewableHeatingData = energy && getRenewableData(energy, 'heating')
  const renewableElectricityData =
    energy && getRenewableData(energy, 'electricity')
  const energyEfficiencyData =
    energyEfficiencyAchieved &&
    getEnergyEfficiencyData(energyEfficiencyAchieved)

  const meatConsumptionData = food && getMeatData(food)
  const foodWasteData = food && getFoodWasteData(food)
  const paperData = paper && getPaperData(paper)
  const waterData = water && getWaterData(water)

  function getEnergyEfficiencyColor() {
    const length = energyEfficiencyData.length
    const [lastYear, thisYear] = [
      energyEfficiencyData[length - 2],
      energyEfficiencyData[length - 1],
    ]
    const color = lastYear.y > thisYear.y ? 'red' : 'green'

    return color
  }

  return (
    <Layout>
      <div tw="flex-1 flex flex-col lg:flex-row lg:overflow-x-auto">
        <CockpitList>
          <CockpitHeader title="Mobility">
            <Mobility tw="w-10 h-10" />
          </CockpitHeader>
          <CockpitCard
            isInactive={!airData}
            title="Air Travel"
            lineData={airData}
            unit="kg CO₂e/FTE"
            link="/air/"
          />
          <CockpitCard title="Train Travel Abroad" isInactive />
          <CockpitCard
            isInactive={!carsData}
            title="Business Vehicle Use"
            lineData={carsData}
            unit="kg CO₂e/FTE"
            link="/cars/"
          />
        </CockpitList>

        <CockpitList>
          <CockpitHeader title="Energy">
            <House tw="w-10 h-10" />
          </CockpitHeader>
          <CockpitCard
            isInactive={!heatingData}
            title="Heating"
            lineData={heatingData}
            unit="kWh/m²"
            link={'/energy/'}
            showTooltip={heatingData}
          />
          <CockpitCard
            isInactive={!electricityData}
            title="Electricity"
            lineData={electricityData}
            unit="kWh/m²"
            link={'/energy/'}
            showTooltip={electricityData}
          />
          <CockpitCard
            isInactive={!fossilData}
            title="Fossil Emissions"
            lineData={fossilData}
            unit="kg CO₂e/m²"
            link={'/energy/'}
            showTooltip={fossilData}
          />
          {renewableHeatingData && (
            <CockpitCard
              isInactive={!renewableHeatingData}
              title="Share Renewable Heating"
              lineData={renewableHeatingData}
              unit="%"
              link={'/energy/'}
              showTooltip={renewableHeatingData}
            />
          )}
          <CockpitCard
            isInactive={!renewableElectricityData}
            title="Share Renewable Electricity"
            lineData={renewableElectricityData}
            unit="%"
            link={'/energy/'}
            showTooltip={renewableElectricityData}
          />
          <CockpitCard title="Photovoltaic Own Production" isInactive />
          <CockpitCard
            isInactive={!energyEfficiencyData}
            title="Energy Efficiency Target (ZH ZVB)"
            lineData={energyEfficiencyData}
            unit={(y) => (y === 100 ? 'Yes' : 'No')}
            hideValue
            customColor={
              energyEfficiencyData ? getEnergyEfficiencyColor() : undefined
            }
            link={'/energy/'}
          />
        </CockpitList>

        <CockpitList>
          <CockpitHeader title="Resources">
            <Globe tw="w-10 h-10" />
          </CockpitHeader>
          <CockpitCard
            isInactive={!meatConsumptionData}
            title="Meat Consumption"
            lineData={meatConsumptionData}
            unit="g/main meal"
            link={'/food/'}
          />
          <CockpitCard
            isInactive={!foodWasteData}
            title="Food Waste"
            lineData={foodWasteData}
            unit="g/main meal"
            link={'/food/'}
          />
          <CockpitCard
            isInactive={!paperData}
            title="Paper Consumption"
            lineData={paperData}
            unit="t"
            link={'/paper/'}
          />
          <CockpitCard
            isInactive={!waterData}
            title="Water Consumption"
            lineData={waterData}
            unit="m³"
            link={'/water/'}
            showTooltip={waterData}
          />
          <CockpitCard title="Waste" isInactive />
          <CockpitCard title="Share Recyclable Waste" isInactive />
        </CockpitList>

        <CockpitList tw="bg-grey-9" parentStyles={tw`relative border-r-0`}>
          <div>
            <CockpitHeader
              title="Live Experiments"
              tw="after:(content absolute inset-0 bg-black opacity-25)"
            >
              <div tw="w-10 h-10 rounded-full p-3 bg-blue">
                <Experiments tw="fill-current text-white" />
              </div>
            </CockpitHeader>
            <div tw="filter blur-sm">
              <CockpitExperimentsItem
                title="Dress warm week"
                subtitle="Campaign"
                badges={['energy consumption']}
              />
              <CockpitExperimentsItem
                title="We collect your backside"
                subtitle="Activity"
                badges={['fossil energy', 'paper consumption']}
              />
              <CockpitExperimentsItem
                title="Funding Meetings"
                subtitle="Awareness"
                badges={['air', 'train']}
              />
              <CockpitExperimentsItem
                title="Vegan Lifestyle"
                subtitle="Campaign"
                badges={['Meat & fish']}
              />
            </div>
          </div>
          <div tw="absolute inset-0 flex items-center bg-black bg-opacity-25">
            <div tw="w-full text-center">
              <span tw="rounded-lg bg-grey-5 p-2 px-4 shadow-inner filter contrast-75 text-lg">
                Upcoming feature
              </span>
            </div>
          </div>
        </CockpitList>
      </div>
    </Layout>
  )
}
