import { getByYear } from '../../util'

const WEIGHT = 't'

function getPaperData(data) {
  const paper = data.map((d) => ({
    x: d.year,
    y: d[WEIGHT],
  }))

  return getByYear(paper)
}

export { getPaperData }
