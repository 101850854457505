import React from 'react'
import CockpitBadge from './CockpitBadge'
import CockpitChart from './CockpitChart'
import 'twin.macro'

export default function CockpitHeader({
  children,
  title,
  subtitle,
  badgeValue,
  lineData,
  ...rest
}) {
  return (
    <div
      tw="z-10 sticky top-0 h-20 px-4 pt-4 pb-5 flex justify-between items-center bg-grey-9"
      {...rest}
    >
      <div tw="flex items-center">
        <div tw="mr-4">{children}</div>
        <div tw="flex flex-col ">
          <span tw="text-grey-1 text-sm font-bold tracking-wide uppercase">
            {title}
          </span>
          <span tw="text-xs text-grey-7">{subtitle}</span>
        </div>
      </div>
      <div tw="flex items-center">
        <CockpitChart lineData={lineData} />
        <CockpitBadge value={badgeValue} tw="ml-3" />
      </div>
    </div>
  )
}
