import React from 'react'
import 'twin.macro'

import { capitalize } from '../util'

export default function OverviewTooltipContent({ tooltipData }) {
  return (
    <div tw="w-auto">
      <div tw="flex justify-between">
        <span>{capitalize(tooltipData.label)}</span>
        <span tw="pl-2 text-blue font-bold">
          {tooltipData.value}{' '}
          {tooltipData.unit && (
            <span tw="text-xs font-normal">{tooltipData.unit}</span>
          )}
        </span>
      </div>
      {tooltipData.sum && (
        <div tw="mt-2 pt-2 flex justify-between border-t border-grey-5">
          <span>Sum</span>
          <span tw="pl-2 text-blue font-bold">
            {tooltipData.sum}{' '}
            {tooltipData.unit && (
              <span tw="text-xs font-normal">{tooltipData.unit}</span>
            )}
          </span>
        </div>
      )}
    </div>
  )
}
