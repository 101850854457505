import React from 'react'
import 'twin.macro'

export default function CockpitList({ children, parentStyles, ...rest }) {
  return (
    <div
      tw="flex-1 flex flex-col flex-shrink-0 border-r border-grey-5 border-opacity-60"
      css={parentStyles}
    >
      <div
        tw="lg:h-0 overflow-y-auto flex-grow flex-shrink-0 overflow-x-hidden"
        {...rest}
      >
        {children}
      </div>
    </div>
  )
}
