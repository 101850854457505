import React, { useCallback } from 'react'
import { format } from 'd3-format'
import tw from 'twin.macro'
import OverviewTooltipContent from './OverviewTooltipContent'
import useSVGTooltip from '../connectors/useSvgTooltip'

const valueFormatter = format('.1f')

const variants = {
  green: tw`bg-green-200 text-green-800`,
  red: tw`bg-red-200 text-red-800`,
}

function getLastAndThisYear(data) {
  const length = data.length
  return [data[length - 2], data[length - 1]]
}

function getPercentageChange(data) {
  const [lastYear, thisYear] = getLastAndThisYear(data)
  const change =
    thisYear.y === lastYear.y
      ? 0
      : ((thisYear.y - lastYear.y) / lastYear.y) * 100
  const formattedChange = valueFormatter(Math.min(100, change))

  return change <= 0 ? `${formattedChange}%` : `+${formattedChange}%`
}

function getLabel(data) {
  const [lastYear, thisYear] = getLastAndThisYear(data)
  return `${lastYear.x} - ${thisYear.x}`
}

export default function CockpitPercentageBadge({ data, customColor, ...rest }) {
  const change = getPercentageChange(data)
  const bgColor = change.startsWith('+') ? 'red' : 'green'

  const {
    containerRef,
    handlePointer,
    TooltipInPortal,
    hideTooltip,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    tooltipData,
    tooltipStyles,
  } = useSVGTooltip()

  const handleTooltip = useCallback(
    (e, { label, value }) => {
      handlePointer(e, {
        label,
        value,
      })
    },
    [handlePointer]
  )

  return (
    <div
      css={[
        variants[customColor ?? bgColor],
        tw`w-14 h-6 inline-flex text-center items-center justify-center  font-size[0.813rem] leading-6 tracking-tight font-bold rounded-2xl`,
      ]}
      ref={containerRef}
      onPointerEnter={(e) =>
        handleTooltip(e, { label: getLabel(data), value: change })
      }
      onPointerLeave={hideTooltip}
      {...rest}
    >
      {change}

      {tooltipOpen && (
        <>
          <TooltipInPortal
            left={tooltipLeft}
            top={tooltipTop}
            style={tooltipStyles}
          >
            <OverviewTooltipContent tooltipData={tooltipData} />
          </TooltipInPortal>
        </>
      )}
    </div>
  )
}
