import { rollups } from 'd3-array'

import {
  haul,
  sortHaulOrder,
} from '../../connectors/dataSelection/filters/useHaul'
import { location } from '../../connectors/dataSelection/filters/useLocation'
import {
  QUARTERS,
  sortQuarters,
  time,
} from '../../connectors/dataSelection/filters/useTime'
import { flightCountIndicator } from '../../connectors/indicatorTripModeContext/indicators'
import { resolveChildren as resolver } from '../../connectors/dataSelection/useDataFilters'

/**
 * TODO: Create new reducers for these functions - don't rely on a single one to generate explore charts.
 * Changing that reducer might also affect this one.
 */

function getTrendData(yearlyFte, data) {
  const trendData = Array.from(
    rollups(
      data,
      (v) => reducer(yearlyFte, v),
      (d) => time(d.leg_date, { interval: QUARTERS })
    ),
    resolver
  )
  const sortedTrendData = trendData.sort((a, b) => sortQuarters(a.name, b.name))

  // Ensure data starts from Q1 for proper bottom axis labels
  const indexOfFirstQ1 = sortedTrendData.findIndex((e) =>
    e.name.startsWith('Q1')
  )
  const slicedTrendData = sortedTrendData.slice(indexOfFirstQ1)

  return slicedTrendData
}

function getTopTenDestinationsData(
  yearlyFte,
  orgFteByYear,
  airports,
  data,
  year = '2020'
) {
  const { reducer } = flightCountIndicator

  const locationData = Array.from(
    rollups(
      data,
      (v) => reducer(yearlyFte, orgFteByYear, false)(v),
      (d) => time(d.leg_date),
      (d) => location(airports, d.from, d.to)
    ),
    resolver
  )

  const topTenDestinationsData = locationData
    .find((e) => e.name === year)
    .children.sort((a, b) => b.size - a.size)
    .slice(0, 10)

  return topTenDestinationsData
}

function getPieData(yearlyFte, orgFteByYear, data, indicator, year = '2020') {
  const { reducer } = indicator

  const haulData = Array.from(
    rollups(
      data,
      (v) => reducer(yearlyFte, orgFteByYear, false)(v),
      (d) => time(d.leg_date),
      (d) => haul(d)
    ),
    resolver
  )

  const pieData = haulData
    .find((e) => e.name === year)
    .children.sort((a, b) => sortHaulOrder(a.name, b.name))

  return pieData
}

function getAirData(yearlyFte, data) {
  const emission = rollups(
    data,
    (v) => reducer(yearlyFte, v),
    (d) => time(d.leg_date)
  ).map((d) => ({
    x: d[0],
    y: d[1],
  }))

  return emission
}

export { getTrendData, getTopTenDestinationsData, getPieData, getAirData }

function getItemYear(d) {
  return new Date(d.leg_date).getFullYear()
}

function getFte(yearlyFte, d) {
  return yearlyFte[getItemYear(d)] ?? 1
}

function getCO2(d) {
  return d['CO2e RFI2 (t)'] + 0.152 * d['CO2e (t)']
}

function reducer(yearlyFte, items) {
  return items.reduce((acc, d) => {
    return acc + (getCO2(d) / getFte(yearlyFte, d)) * 1000
  }, 0)
}
