import React from 'react'
import 'twin.macro'

export default function CockpitBadge({ value, ...rest }) {
  return (
    <div
      tw="px-2 inline-flex items-center justify-center bg-grey-5 text-grey-1  font-size[0.813rem] leading-6 tracking-tight rounded-2xl"
      {...rest}
    >
      {value}
    </div>
  )
}
